import axios from '../utils/axios'

export function login(data) {
  data.type = 'password'
  return axios({
    url: '/supplier/fmcg/login',
    method: 'post',
    data
  })
}
export function loginBrand(data) {
  data.type = 'password'
  return axios({
    url: '/supplier/dealer/login-one',
    method: 'post',
    data
  })
}
export function addUser(data) {
  return axios({
    url: '/website/add-user-info',
    method: 'post',
    data
  })
}
export function sendMsg(data) {
  return axios({
    url: '/website/send-msg',
    method: 'post',
    data
  })
}