import axios from 'axios'
import router from '../router'
import { ElNotification } from 'element-plus'

axios.defaults.transformRequest = [function(data) {
  if (!data) return data // 没有，直接返回
  return dataToFormData(data)
}]
function dataToFormData(oData) {
  const oFormData = new FormData()
  for (const [key, val] of Object.entries(oData)) {
    if (Array.isArray(val)) { // 是
      for (const oneItem of val) oFormData.append(key, oneItem)
    } else {
      oFormData.append(key, val)
    }
  }
  return oFormData
}
const service =axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // api2 的 base_url
  baseURL:'https://kxp.mywhh.com/api',
  headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
  dataType: "json",
  timeout: 5000 // request timeout
})
// Vue.config.productionTip = false
// Vue.prototype.$axios = instance
// axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
// axios.defaults.headers['token'] = localStorage.getItem('token') || ''
// axios.defaults.headers.post['Content-Type'] = 'application/json'

service.interceptors.response.use(res => {
  if (typeof res.data !== 'object') {
    ElNotification({
      title: 'Error',
      message: '服务端异常',
      type: 'Error',
    })
    return Promise.reject(res)
  }
  if (res.data.error != 0) {
    if (res.data.error == 401) {
      ElNotification({
        title: 'Error',
        message: '请登录',
        type: 'error',
      })
      router.push({ path: '/login' })
    }
    ElNotification({
      title: 'Error',
      message: res.data.message,
      type: 'error',
    })
    return Promise.reject(res.data)
  }
  return res.data
})

export default service